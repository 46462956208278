.user-dropdown .dropdown-toggle::after {
  display: none !important;
}

.pointer {
  cursor: pointer !important;
}

.skiptranslate {
  display: none !important;
}

.manual-approve .ref-no {
  width: 150px;
}

.manual-approve .btn {
  width: 100px;
}

.pagination-utility .total-records {
  height: 39px;
  display: flex;
  align-items: center;
}

.statement-type-btn {
  height: 29px;
  min-width: 70px;
}

.payment-page .timer {
  min-height: 30px;
}

.payment-page .qr-code-container {
  min-height: 275px;
}

.payment-page {
  min-height: 100vh;
  background-color: #ddd;
  font-size: 14px;
}

.payment-page .payment-box {
  max-width: 100%;
  width: 650px;
}

.payment-page .payment-header {
  width: 100%;
}

.payment-page .payment-body {
  width: 100%;
}

.payment-page .time {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: bold;
  margin: 0 auto;
  border: 5px solid;
}

.payment-methods .list-group-item + .list-group-item {
  border-top-width: 1px;
}

.payment-methods .list-group-item.active .badge {
  display: block;
}

.payment-methods .list-group-item .badge {
  display: none;
}

.payment-methods .list-group-item .pay-icon {
  width: 50px;
}

.payment-methods .list-group-item {
  height: 58px;
  color: #5d87ff !important;
  background-color: transparent !important;
  border-color: #5d87ff !important;
}

.payment-methods .list-group-item.active {
  color: #fff !important;
  background-color: #5d87ff !important;
  border-color: #5d87ff !important;
}

.payment-methods .pay-btn {
  min-height: 40px;
}

.error-404 {
  min-height: calc(100vh - 110px);
}

.st-options-col {
  max-width: 500px;
  flex-wrap: wrap;
}

.shared-merchants-col {
  max-width: 600px;
  min-width: 300px;
  flex-wrap: wrap;
}

.spinner-border {
  height: 16px;
  width: 16px;
}

.payment-page {
  min-height: 100vh;
  background-color: #ddd;
  font-size: 14px;
}

.payment-box {
  max-width: 90%;
  width: 600px;
}

.time {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: bold;
  margin: 0 auto;
  border: 5px solid;
}

.message-box {
  text-align: center;
  padding: 10px;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.message-box img {
  max-height: 200px;
  margin-bottom: 10px;
}
.user-whitelist{
    display: flex;
    flex-wrap: wrap;
    gap: 5px
}
.user-whitelist .user{
    background-color: hsl(0, 0%, 90%);
    margin: 2px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
}
.user-whitelist .user > span{
    padding: 2px 4px;
}
.user-whitelist .user .close{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    cursor: pointer;
    height: 100%;
    border-radius: 4px;
    width: 24px;
}
.user-whitelist .user:hover .close{
    background: #ff00003b;
    
}
@media screen and (min-width: 768px) {
  .analytics-merchant {
    max-width: 300px;
  }
}
